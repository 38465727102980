import * as React from 'react'
import StockBackground from "../images/general/forest.webp"
import {graphql, useStaticQuery} from "gatsby";
import {GatsbyImage} from "gatsby-plugin-image";

function SingleImage(props){
    return(
            <img className={"mx-auto"} src={props.src} alt={props.alt}/>
    )
}

function ImageGallery(){

    const data = useStaticQuery(graphql`
{
allFile(filter: {relativeDirectory: {eq: "images/gallery"}}) {
    edges {
      node {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  }
}



`)

    const ImageContent = data.allFile.edges

    return(
        <div className={"dark:bg-gray-900 dark:text-white transition duration-500"}>
        <div className={"grid grid-cols-1 sm:grid-cols-3 gap-10 max-w-5xl mx-auto py-10"}>
            {
                ImageContent.map(node => (
                        <GatsbyImage className={"mx-auto"} alt={"Example Work"} image={node.node.childImageSharp.gatsbyImageData}/>
                ))
            }

        </div>
        </div>
    )
}

export default ImageGallery
import * as React from 'react'
import {graphql, useStaticQuery} from "gatsby";
import BackgroundImage from 'gatsby-background-image'
import Button from "./button";
import {StaticImage} from "gatsby-plugin-image";
import {GatsbyImage} from "gatsby-plugin-image";

export default function Hero(){
    const data = useStaticQuery(graphql`
{
  fileName: file(relativePath: {eq: "images/hero-home.jpg"}) {
    childImageSharp {
        gatsbyImageData
      }
  }
  allDataJson {
    edges {
        node {
            About_Us
            Better_Explanation
            Business_Name
            Contact_Details {
                Address
                Email
                Hours
                Phone
                Phone_Without_Formatting
                Service_Areas
            }
            Expert_Explanation
            Main_Services {
                Description
                Title
            }
            Other_Services {
                Description
                Title
            }
            Pages {
                Image
                Title
                Page_Address
            }
            Qualities
            Tag_Line
            Title
            Years_Experience
        }
    }
  }
}



`)
    const WebsiteContent = data.allDataJson.edges[0].node

    const ImageContent = data.fileName.childImageSharp.gatsbyImageData;

    return(
        <div>
            <div style={{ display: "grid" }}>
                {/* You can use a GatsbyImage component if the image is dynamic */}
                <GatsbyImage
                    style={{
                        gridArea: "1/1",
                        maxHeight: "827px",
                        // You can set a maximum height for the image, if you wish.
                        // maxHeight: 600,
                    }}
                    layout="fullWidth"
                    // You can optionally force an aspect ratio for the generated image
                    aspectRatio={3 / 1}
                    // This is a presentational image, so the alt should be an empty string
                    alt=""
                    // Assisi, Perúgia, Itália by Bernardo Ferrari, via Unsplash
                    image={
                        ImageContent
                    }
                    formats={["auto", "webp", "avif"]}
                />
                <div
                    style={{
                        // By using the same grid area for both, they are stacked on top of each other
                        gridArea: "1/1",
                        position: "relative",
                        // This centers the other elements inside the hero component
                        placeItems: "center",
                        display: "grid",
                    }}
                >
                    <div className={"bg-black/50 dark:bg-black/75 w-full"}>
                        <div className={"h-full lg:py-36 lg:pb-52 pb-20 pt-14 flex flex-col justify-center items-center max-w-3xl mx-auto font-bold"}>
                            <h1 className={"lg:leading-relaxed text-5xl lg:text-6xl text-center text-white p-6 "}>{WebsiteContent.Title}</h1>
                            <div className={"h-1 w-24 bg-main-color-500"}></div>
                            <p className={"text-md text-center text-white p-6 pt-10 lg:pt-20 leading-loose"}>{WebsiteContent.Tag_Line}</p>
                            <Button className={""} to={"/contact#contact-form"}>Get a Free Quote!</Button>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}